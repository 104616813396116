<template>
  <v-layout wrap class="justify-center">
    <v-flex lg12 md12 sm12 pr-2>
      <v-container container--fluid>
        <v-layout>
          <page-title>Image generation queue</page-title>

          <div class="ml-4 mt-1 mb-2">
            <v-btn class="secondary--text mt-2 mr-3" text outlined small @click="refresh()">
              <v-icon small class="mr-1">refresh</v-icon>
              Refresh
            </v-btn>
          </div>
        </v-layout>

        <v-layout class="mt-4">
          <p class="secondary--text caption">View all image generation requests in the queue.
          </p>
        </v-layout>

        <!-- Error message -->
        <v-layout>
          <div v-if="error">
            <v-alert type="error" dense outlined>
              {{ error }}
            </v-alert>
          </div>
        </v-layout>

        <!-- delete confirmation modal -->
        <v-dialog v-model="modal" max-width="500px">
          <v-card>
            <v-card-title>Confirmation Required</v-card-title>
            <v-card-text>
              <p>
                Are you sure want to delete image generate request '{{ deletingImageGeneration.id }}'?
              </p>
              <p>
                Data will be deleted permanently.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn text v-on:click.native="modal = false">Cancel</v-btn>
              <v-spacer> </v-spacer>
              <v-btn v-on:click.native="deleteImageGen(deletingImageGeneration)" text class="primary">
                Remove
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-layout class="mt-2">
          <div class="tbcard">
            <v-card>
              <v-data-table show-select v-model="selected" :key="tableKey" :headers="headers" :items="imageGens"
                hide-default-footer :search="e3" :loading="loading" :server-items-length="pagination.totalItems"
                loading-text="Loading... Please wait">

                <template v-if="selected" v-slot:top>
                  <v-row>
                    <v-layout wrap class="justify-end mt-5 mr-5 ml-5">

                      <v-flex lg2 md5 sm12 height="25px">
                        <v-select class="mr-2" outlined dense v-model="model" :items="models" item-text="name"
                          item-value="id" label="Filter by model">
                        </v-select>
                      </v-flex>
                      
                      <v-flex lg2 md5 sm12 height="25px">
                        <v-select class="mr-2" outlined dense v-model="status" :items="statuses" item-text="text"
                          item-value="value" label="Filter by status">
                        </v-select>
                      </v-flex>

                      <v-flex lg2 md2 sm12 class="mr-4">

                        <v-btn height="40px" outlined class="secondary--text"
                          :disabled="selected && selected.length === 0" @click="deleteSelected()">
                          <v-icon small class="mr-1">delete</v-icon>
                          Delete Selected
                        </v-btn>
                      </v-flex>

                    </v-layout>
                  </v-row>
                </template>

                <template v-slot:[`item.createdAt`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.createdAt | ago }}
                      </span>
                    </template>
                    <span>{{ item.createdAt | date }}</span>
                  </v-tooltip>
                </template>

                {{/* */ }}

                <template v-slot:[`item.status`]="{ item }">
                  <v-tooltip max-width="400px" top :key="item.id">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">

                        <v-chip v-if="item.status === 'pending'" class="ma-2" label small color="secondary">
                          {{ item.status }}
                        </v-chip>

                        <v-chip v-if="item.status === 'processing'" class="ma-2" label small color="secondary">
                          {{ item.status }}
                        </v-chip>

                        <v-chip v-if="item.status === 'failed'" class="ma-2" label small color="error" text-color="white">
                          {{ item.status }}
                        </v-chip>

                        <v-chip v-if="item.status === 'completed'" class="ma-2" label small color="primary"
                          text-color="white">
                          {{ item.status }}
                        </v-chip>

                      </span>
                    </template>
                    <span>{{ item.reason }}</span>
                  </v-tooltip>
                </template>
                
                <template v-slot:[`item.modelId`]="{ item }">
                  <!-- TODO: fix deleted models -->
                  <div v-if="!item.modelName || item.modelName == ''" class="text--secondary">
                    (deleted)
                  </div>
                  <router-link v-else :to="{
                    name: 'modelDetails',
                    params: {
                      modelId: item.modelId,
                    },
                  }">{{ `${item.modelName ? item.modelName : '(deleted)'}` }}
                  </router-link>
                </template>

                <template v-slot:[`item.input`]="{ item }">

                  <v-tooltip max-width="350px" top :key="`body-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        <span class="mt-3 d-inline-block text-truncate" style="max-width: 200px;">
                          {{ item.requestBody | b64decode }}
                        </span>
                      </span>
                    </template>
                    <span>{{ item.requestBody | b64decode }}</span>
                  </v-tooltip>


                  <v-icon class="ml-2 mb-2" @click="copyToClipboard(item.requestBody)">
                    mdi-content-copy
                  </v-icon>
                </template>

                <template v-slot:[`item.outputImages`]="{ item }">
                  <template v-for="(output, i) in item.outputImages">

                    <v-btn small text outlined class="secondary--text" :href="output.url" target="_blank" v-bind:key="i">
                      <v-icon small class="mr-1">mdi-open-in-new</v-icon>
                      View
                    </v-btn>
                    <!-- <v-btn small text outlined class="secondary--text" @click="downloadFile(output.url, `${output.createdAt}-${output.id}`)" v-bind:key="i">
                      <v-icon small class="mr-1">mdi-file-download</v-icon>
                      Download
                    </v-btn> -->

                  </template>
                </template>

                <template v-slot:[`item.priority`]="{ item }">
                  <v-tooltip top :key="`del-${item.id}`">
                    <template v-slot:activator="{ on: tooltip }">
                      <span v-on="{ ...tooltip }">
                        {{ item.priority }}
                      </span>
                    </template>
                    <span>Priority class '{{ item.priorityClass }}'</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu class="items" top left transition="v-slide-y-transition">
                    <template v-slot:activator="{ on: on }">
                      <v-btn icon v-on="on" :class="
                        $store.state.theme === 'light' ? 'secondary--text' : ''
                      ">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="deleteImageGen(item)">
                        <v-list-item-title>delete</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="item.outputImages.length > 0"
                        @click="downloadFile(item.outputImages[0].url, `${item.outputImages[0].createdAt}-${item.outputImages[0].id}`)">
                        <v-list-item-title>download</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <div class="pr-2 pb-2">
                <v-layout class="mt-2">
                  <v-spacer></v-spacer>
                  <span class="text-caption mt-3 mr-4">Rows per page:</span>
                  <div class="rows-select">
                    <v-flex shrink class="select">
                      <v-select class="mb-2" width="25" v-model="itemsPerPage" :items="itemsPerPageOptions" dense
                        label="Rows per page" single-line></v-select>
                    </v-flex>
                  </div>
                  <span class="text-caption mt-3 mr-4 ml-4">Total items: {{ pagination.totalItems }}</span>
                  <v-btn class="mr-2 mt-2" icon small router :disabled="pagination.previousPageToken == '' ? true : false"
                    :to="{
                      name: 'queue',
                      query: {
                        pageToken: pagination.previousPageToken,
                      },
                    }">
                    <v-icon>navigate_before</v-icon>
                  </v-btn>
                  <!-- Navigate to the next page based on the token -->
                  <v-btn class="ml-2 mt-2" icon small router :disabled="pagination.nextPageToken == '' ? true : false"
                    :to="{
                      name: 'queue',
                      query: {
                        pageToken: pagination.nextPageToken,
                      },
                    }">
                    <v-icon>navigate_next</v-icon>
                  </v-btn>
                </v-layout>
              </div>
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
// import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';

export default {
  components: {
    // PageSubtitle,
    PageTitle,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'Timestamp', align: 'left', value: 'createdAt', sortable: true },
        { text: 'User', value: 'user', align: 'left' },
        { text: 'Model', value: 'modelId', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Input', value: 'input', align: 'left' },
        { text: 'Outputs', value: 'outputImages', align: 'left' },
        { text: 'Priority', value: 'priority', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      modal: false,
      deletingImageGeneration: {
        id: ''
      },
      selected: [],
      itemsPerPage: 10,
      itemsPerPageOptions: [
        10,
        20,
        200,
        500,
      ],
      model: '',
      status: '',
      statuses: [
        { text: 'Pending', value: 'pending' },
        { text: 'Processing', value: 'processing' },
        { text: 'Completed', value: 'completed' },
        { text: 'Failed', value: 'failed' },
      ]
    }
  },

  watch: {
    // Table pagination
    itemsPerPage: {
      handler() {
        // If user is changing items per page, we kinda need
        // to reset the token, otherwise going backwards might
        // be weird
        let reset = this.itemsPerPage !== this.pagination.pageSize ? true : false
        this.$store.dispatch('SetImageGensPageSize', this.itemsPerPage).then(() => {
          if (reset && this.pageTokenFromUrl) {
            this.$router.push({ name: 'queue' }).catch(() => { })
          } else {
            this.fetchImageGens()
          }
        })
      }
    },
    model: {
      handler() {
        this.fetchImageGens()
      }
    },
    status: {
      handler() {
        this.fetchImageGens()
      }
    },
    // // Debouncing is needed to avoid calling API multiple times while
    // // the user is still typing and we call it immediately if the filter
    // // is removed
    // filter: debounce(function (newVal) {
    //   this.fetchfetcDevices(newVal)
    //   this.fetchDevicesMetrics(this.filter)
    // }, 1000),
    // devices: {
    //   handler() {
    //     if (this.devices.length > 0) {
    //       this.projectHasDevices = true
    //     }
    //   }
    // },
    $route() {
      this.fetchImageGens()
    }
  },

  computed: {
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    pageTokenFromUrl() {
      return this.$route.query.pageToken
    },
    models() {
      return this.$store.state.model.models
    },
    loading() {
      return this.$store.state.imageGens.loading
    },
    error() {
      return this.$store.state.imageGens.error
    },
    imageGens() {
      return this.$store.state.imageGens.imageGens
    },
    pagination() {
      return this.$store.state.imageGens.pagination
    },
  },

  async mounted() {
    // Loading pagination preferences
    await this.$store.dispatch('LoadImageGensPageSize')

    this.itemsPerPage = this.pagination.pageSize

    this.refresh()
  },

  methods: {
    async refresh() {
      await this.$store.dispatch('GetUser')

      // models are needed to lookup the model Id in the image gen
      await this.$store.dispatch('ListModels', { projectId: this.project.id })

      // Getting the image gens
      await this.fetchImageGens()

      this.$nextTick(() => {
        this.tableKey = Date.now()
      })
    },
    async fetchImageGens() {
      if (!this.project) {
        return
      }

      let q = {
        models: this.models, // models are for name lookup
        projectId: this.project.id,
        pageSize: this.pagination.pageSize,
        model: this.model,
        status: this.status,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
      }

      await this.$store.dispatch('ListImageGens', q)
    },
    deleteImageGenIntent(item) {
      console.log(item)
    },
    async deleteImageGen(item) {
      await this.$store.dispatch('DeleteImageGen', { projectId: item.projectId, id: item.id })
      await this.refresh()
    },
    async deleteSelected() {
      // Dispatch DeleteImageGen for each item in the selected array and resolve their promises
      await Promise.all(this.selected.map(item => this.$store.dispatch('DeleteImageGen', { projectId: item.projectId, id: item.id })))
      await this.refresh()
    },
    downloadFile(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    },
    copyToClipboard(value) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(atob(value))
    }
  }
}
</script>
